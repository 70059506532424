<template>
  <div>
<div class="header_box">
         <div class="header main">
      <div class="header_left">
        <img height="100%" src="@/assets/image/logo.png" alt="" />
      </div>
      <div class="header_right  d-none d-sm-block">
        <el-menu router :default-active="$route.path"
         active-text-color="#fff"
         text-color="#fff"
         class="el-menu-demo"
          mode="horizontal">
          <el-menu-item index="/homepage">首页</el-menu-item>
          <el-menu-item index="/productLine">产品线</el-menu-item>
          <el-menu-item index="/application">应用软件</el-menu-item>
          <el-menu-item index="/industry">行业方案</el-menu-item>
          <el-menu-item index="/my">联系我们</el-menu-item>
        </el-menu>
      </div>
        <!-- 弹框 -->
      <div class="header_right d-sm-none">
        <div class="dropdown">
          <div id="dropdownMenuButton1" data-bs-toggle="dropdown">
            <img src="@/assets/image/Banner/c6.png" alt="" />
          </div>
          <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
            <!-- <li class="dropdown_li"></li> -->
            <el-menu
              router
              :default-active="$route.path"
              active-text-color="##0D36CE"
              text-color="##333333 "
              class="el-menu-demo"
            >
              <el-menu-item index="/homepage">首页</el-menu-item>
              <el-menu-item index="/productLine">产品线</el-menu-item>
              <el-menu-item index="/application">应用软件</el-menu-item>
              <el-menu-item index="/industry">行业方案</el-menu-item>
              <el-menu-item index="/my">联系我们</el-menu-item>
            </el-menu>
          </ul>
        </div>
      </div>
    </div>
</div>
    <!-- 应用软件start -->
    <Software></Software>
    <!-- 应用软件end -->

     <!-- footer start -->
    <Footer></Footer>
    <!-- footer end -->
  </div>
</template>

<script>
import Footer from '../../components/footer.vue'
import Software from '../../components/software'
export default {
  components: { Footer, Software },
  data() {
    return {
      activeName: "index1"
    }
  }
}
</script>

<style lang="scss" scoped>
// header start
.el-menu{
  background: rgba($color: #000000, $alpha: .0);
}
/deep/.el-submenu__title:hover, .el-menu-item:focus, .el-menu-item:hover{
background-color: rgba($color: #000000, $alpha: .0) !important;
}
.el-menu.el-menu--horizontal {
    border:none;
}
.header_box{
  max-width: 1920px;
  margin: 0 auto;
  background: #4066F5;
}
.header_box{
  max-width: 1920px;
  margin: 0 auto;
  background: #4066F5;
  height: 80px;
}
.header{
  // background: #4066F5;
  display: flex;
}
.header_left{
  position: absolute;
  top: 16px;
}
.header_right {
  position: relative;
  left: 66%;
  top: 16px;
}

// header end

@media only screen and (max-width: 760px) {
  .header_box{
    height: 53px;
  }
    .header {
    width: calc(100vw);
  }
   .header_left {
     height: 28px;
    margin-left: 18px;
    margin-top: -2px;
  }
.header_right{
  left: 90%;
position: relative;
top: 15px;
}
  .dropdown{
    margin-bottom: 20px;
  }
  .dropdown-menu {
    width: calc(100vw);
    height: calc(100vh);
    background: #fff;
  }
}
</style>
